import { Heading } from '@/components/Heading'
import { Button } from '@/components/Button'

export default function Error({
    error,
    url,
    heading,
}: {
    error?: Error
    url?: string
    heading: string
}) {
    return (
        <div className="w-full h-screen bg-secondary text-white p-4">
            <Heading text={heading} align="center" />
            <div className="h-1/2 bg-contain text-secondary bg-no-repeat bg-center bg-[url('../public/error.svg')] text-center flex justify-center items-center pr-10 font-bold text-2xl">
                {error?.name ?? heading}
            </div>
            <p className="text-center text-lg text-white m-8">
                {error?.message}
            </p>
            <div className="flex justify-center">
                <Button color="primary" outline url={url || '/'}>
                    Go Home
                </Button>
            </div>
        </div>
    )
}
