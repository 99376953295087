import { ButtonProps } from './index'
import { useCallback, useMemo } from 'react'
import { getLinkWrapper } from '@/components/LinkWrapper'

export const useButton = (props: ButtonProps, buttonClasses?: string) => {
    const handlePrintClick = useCallback(() => {
        let wrapper: any = null
        let header: any = null
        let footer: any = null

        window.addEventListener('beforeprint', () => {
            footer = document.querySelector('footer')
            wrapper = footer?.parentElement
            footer?.remove()

            header = document.querySelector('header')
            header?.remove()
        })

        window.addEventListener('afterprint', () => {
            if (!wrapper) {
                return
            }
            wrapper.appendChild(footer)
            wrapper.insertAdjacentElement('afterbegin', header)
        })
        window.print()
    }, [])

    return useMemo(
        () => ({
            type: props.buttonType || 'button',
            className: buttonClasses || '',
            children: props.children || 'Button',
            onClick: props.print ? handlePrintClick : props.onClick,
            Wrapper: getLinkWrapper(props.url, props.openInNewWindow),
            'data-testid': props.testId,
        }),
        [props, buttonClasses, handlePrintClick]
    )
}
