import { createElement } from 'react'

type HeadingProps = {
    text?: string
    tag?: keyof JSX.IntrinsicElements
    align?: 'center' | 'right' | 'left'
    className?: string
}

export const Heading = ({
    text,
    tag = 'h1',
    align = 'left',
    className,
}: HeadingProps) => {
    const Tag = tag
    const headingClass = getHeadingClass(align)
    const props = {
        children: text,
        className: `${headingClass} ${className}`.trim(),
    }
    return createElement(Tag, props)
}

const getHeadingClass = (align: HeadingProps['align']) => {
    if (align === 'center') {
        return 'text-center'
    }

    if (align === 'right') {
        return 'text-right'
    }

    return 'text-left'
}
