import { ReactNode } from 'react'
import Link from 'next/link'

export const getLinkWrapper = (url?: string, openInNewWindow?: boolean) =>
    function LinkWrapper({ children }: { children: ReactNode }) {
        return url ? (
            <Link href={url} target={openInNewWindow ? '_blank' : undefined}>
                {children}
            </Link>
        ) : (
            <>{children}</>
        )
    }
