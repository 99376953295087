'use client'
import { createElement, ReactNode } from 'react'
import clsx from 'clsx'
import { useButton } from './useButton'

export type ButtonProps = {
    print?: boolean
    url?: string
    color?: 'neutral' | 'primary' | 'secondary' | 'accent' | 'ghost' | 'link'
    type?: 'square' | 'circle' | 'wide' | 'block'
    size?: 'lg' | 'sm' | 'xs'
    outline?: boolean
    disabled?: boolean
    active?: boolean
    onClick?: (event: Event) => void
    buttonType?: 'button' | 'submit' | 'reset'
    children: JSX.Element | ReactNode
    rootClasses?: string
    openInNewWindow?: boolean
    testId?: string
}

export const Button = (props: ButtonProps) => {
    const { color, type, size, disabled, active, outline, rootClasses } = props

    const buttonClasses = clsx('btn font-normal scale-100', rootClasses, {
        [getColorClass(color)]: color,
        [getTypeClass(type)]: type,
        [getSizeClass(size)]: size,
        'btn-disabled': disabled,
        'btn-active': active,
        'btn-outline': outline,
    })

    const { Wrapper, ...buttonProps } = useButton(props, buttonClasses)
    return <Wrapper>{createElement('button', buttonProps)}</Wrapper>
}

function getColorClass(color: ButtonProps['color']) {
    if (color === 'accent') {
        return 'btn-accent'
    }
    if (color === 'primary') {
        return 'btn-primary'
    }
    if (color === 'secondary') {
        return 'btn-secondary'
    }
    if (color === 'neutral') {
        return 'btn-neutral'
    }
    if (color === 'ghost') {
        return 'btn-ghost'
    }
    if (color === 'link') {
        return 'btn-link [text-underline-offset:6px] p-0'
    }

    return ''
}

function getTypeClass(type: ButtonProps['type']) {
    if (type === 'wide') {
        return 'px-12'
    }
    if (type === 'block') {
        return 'btn-block'
    }
    if (type === 'square') {
        return 'btn-square'
    }
    if (type === 'circle') {
        return 'btn-circle'
    }

    return ''
}

function getSizeClass(size: ButtonProps['size']) {
    if (size === 'lg') {
        return 'btn-lg'
    }
    if (size === 'sm') {
        return 'btn-sm'
    }
    if (size === 'xs') {
        return 'btn-xs'
    }

    return ''
}
